<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <avue-crud ref="crud" :option="tableOption1" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange" >
            <template #menu-left>
                <el-button   @click="openView(1)">{{$t('currencySetNft.text1')}} </el-button>
                <!-- <el-button   @click="openView(2)">提现 </el-button> -->
                <el-button type="primary"   @click="openView(3)">{{$t('button.add')}} </el-button>
            </template>
            <template #status="scope">
                <el-switch v-model="scope.row.status" :inactive-value='0' :active-value='1' @change='changeStatus(scope.row)'/>
            </template> 
            <template #caozuo="scope">
                <el-button text type="primary"
                    size="small"    @click="openView(4,scope.row)">{{$t('button.update')}} </el-button>
                <!-- <el-button text type="primary"
                    size="small"    @click="openView(5,scope.row)">查看NFT流水 </el-button> -->
            </template> 
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>  
        <el-dialog v-model="innerVisible1" :show-close='false' :close-on-click-modal = 'false'
            width="650" :title="textArray[pageStyle]" >
            <addView v-if='pageStyle === 3' @successFun='successFun' ref='addView1'/>
            <updateView v-else-if='pageStyle === 4' @successFun='successFun' ref='updateView1'/>
            <aggregation v-else-if='pageStyle === 1' @successFun='successFun' ref='aggregation1'/>
            <!-- <addView v-else-if='pageStyle === 2'/>
            <addView v-else-if='pageStyle === 5'/> -->
        </el-dialog>

    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/nft/hotWallet'
    import { tableOption } from '@/const/crud/nft/hotWallet'
    import mixins from '@/views/mixins/page'
    import { ElNotification } from 'element-plus'
    import addView from './add/add.vue'
    import updateView from './update/index.vue'
    import aggregation from './aggregation'
    import apiList from '@/const/apiList'
    import { userStore } from '@/store/user'
    import { turnTimeFun } from '@/utils/util.js'
    const { $t } = getCurrentInstance().proxy;

    let pageObj = mixins(['getAssetswalletinfoList'],{walletType:4})
    const {page,listLoading,sizeChange,currentChange,getList} = pageObj

    const object_ = ref(object);
    const tableOption1 = ref({}); tableOption1.value = tableOption
    const pageStyle = ref(0)
    const textArray = ['',$t('currencySetNft.text1'),$t('currencySetNft.text2'),$t('currencySetNft.text3'),$t('currencySetNft.text4'),'查看NFT流水']
    const innerVisible1 = ref(false)
    const updateView1 = ref(null);
    const aggregation1 = ref(null)
    const appSwitch = ref(false)
    const addView1 = ref(null)

    const changeStatus = (value_) =>{
        let obj_ = {
            walletInfoId: value_.walletInfoId,
            status: value_.status
        }
        if(value_.walletId){
            Api_.updateAssetswalletinfoStatusView(obj_).then(res=>{
                if(res.code ===0 ){
                    ElNotification({
                        message: value_.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const openView = (e,parms) =>{
        pageStyle.value = e
        innerVisible1.value = true
        if(e === 4){
            nextTick(()=>{
                updateView1.value.init_(parms)
            })
        }else if(e === 1){
            nextTick(()=>{
                aggregation1.value.init_()
            })
        }else if(e === 3){
            nextTick(()=>{
                addView1.value.init_()
            })
        }
    }
    const getAppSwitch = ()=>{//获得当前商户下的app是否有打开归集
        apiList.getAppList((data)=>{
            for(let item of data){
                if(item['isNftCellection'] === 1){
                    appSwitch.value = true
                    return 0
                }
            }
        },userStore().getUserInfo[0].tenantId)
    }
    const successFun = ()=>{
        innerVisible1.value = false
        getList(1);getAppSwitch()
    }
    getList(1);getAppSwitch()
</script>
<style lang="scss" scoped>
</style>